tr.rounded-row td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

tr.rounded-row td:first-child {
    border-radius: 10px 0 0 10px;
}

tr.rounded-row td:last-child {
    border-radius: 0 10px 10px 0;

}

.react-datepicker-wrapper {
    width: 100%;
}